<template>
  <div class="app-container">
    <Navbar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
        />
      </div>
    </div>
  </div>
  
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <!--<ol class="carousel-indicators">
      <li
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
      ></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
      <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
    </ol>-->
    <div class="carousel-inner"
    v-for="produto in produtosCard"
    :key="produto.id"
    :produto="produto"
    >
      <div class="carousel-item active" @click="viewProduct(produto)" v-if="produto.id === 1">
        <img v-if="windowWidth >= 768" class="d-block w-100" src="/img/banner_consulti.jpg" alt="First slide" />
        <img v-else class="d-block w-100" src="/img/banner-consulti-768.png" alt="First slide" />
      </div>
      <!--<div class="carousel-item">
        <img class="d-block w-100" src="/img/banner_2.jpg" alt="Second slide" />
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="/img/banner_3.jpg" alt="Third slide" />
      </div>-->
    </div>
    <!--<a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>-->
  </div>
  <div class="row d-flex justify-content-center align-items-center">
    <swiper class="informative-swiper"
      :pagination="{clickable: true}"
      :modules="modules"
      :slides-per-view="slidesPerViewBanner"

      :breakpoints="{
        768: {
          watchOverflow: false
        },
        1200: {
          watchOverflow: true
        }
      }"
    >
      <swiper-slide class="informative-swiper-slide">
        <router-link to="/produtos" class="router-slide router-percent">
          <!--<i class="bi bi-percent"></i>-->
          <svg class="icon-inline icon-5x svg-icon-text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path d="M17.3,34.41,34.41,17.3a.5.5,0,0,0-.71-.71L16.59,33.7a.51.51,0,0,0,0,.71.54.54,0,0,0,.36.14A.52.52,0,0,0,17.3,34.41ZM25.5,51A25.5,25.5,0,1,1,51,25.5,25.53,25.53,0,0,1,25.5,51Zm0-50A24.5,24.5,0,1,0,50,25.5,24.53,24.53,0,0,0,25.5,1ZM19.89,23.79a3.9,3.9,0,1,1,3.9-3.9A3.9,3.9,0,0,1,19.89,23.79Zm0-6.79a2.9,2.9,0,1,0,2.9,2.89A2.9,2.9,0,0,0,19.89,17ZM31.11,35A3.9,3.9,0,1,1,35,31.11,3.9,3.9,0,0,1,31.11,35Zm0-6.79A2.9,2.9,0,1,0,34,31.11,2.9,2.9,0,0,0,31.11,28.21Z"></path></svg>
          <div class="informative-oferta">
            <span>Pediu oferta?</span>
            <p>Por aqui, você encontra certificados digitais com os melhores preços do Brasil!</p>
          </div>
        </router-link>
      </swiper-slide>
      <swiper-slide class="informative-swiper-slide">
        <a href="https://api.whatsapp.com/send?l=pt&phone=5512996470999&text=Novo%20Contato%20via%20Site"
        target="_blank"
        class="router-slide">
          <!--<i class="bi bi-whatsapp"></i>-->
          <svg class="icon-inline icon-5x svg-icon-text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path d="M15,37.2a.47.47,0,0,1-.35-.15.48.48,0,0,1-.13-.48l1.63-6A12.41,12.41,0,0,1,27,12.2a12.58,12.58,0,0,1,12.5,12.4,12.49,12.49,0,0,1-18.25,11l-6.12,1.6A.27.27,0,0,1,15,37.2Zm12-24a11.4,11.4,0,0,0-9.88,17.09.46.46,0,0,1,.05.38L15.73,36l5.47-1.44a.48.48,0,0,1,.37,0A11.38,11.38,0,0,0,35.08,16.54h0A11.28,11.28,0,0,0,27,13.2Zm3.67,18.61a8.91,8.91,0,0,1-3.59-1A15.35,15.35,0,0,1,21,25.47l-.06-.09a7.09,7.09,0,0,1-1.52-3.91,4.35,4.35,0,0,1,1.29-3.18l.07-.08a1.8,1.8,0,0,1,1.3-.59h.7c.44,0,.88.11,1.21.91l.35.84c.3.74.66,1.6.72,1.73a1.14,1.14,0,0,1,0,1.05,4.66,4.66,0,0,1-.91,1.27.85.85,0,0,0-.17.2,9.35,9.35,0,0,0,4.59,3.93.4.4,0,0,0,.15.06c.33-.41.89-1.07,1.07-1.35a1,1,0,0,1,1.38-.37c.37.13,2.2,1,2.44,1.16l.15.07a1.4,1.4,0,0,1,.73.56,3.13,3.13,0,0,1-.17,2.06,4.06,4.06,0,0,1-2.76,2A5.3,5.3,0,0,1,30.69,31.81ZM22.1,18.62a.7.7,0,0,0-.61.28l-.07.07a3.34,3.34,0,0,0-1,2.5,6,6,0,0,0,1.31,3.3l.09.13a14.27,14.27,0,0,0,5.7,5c2.1.91,2.91,1,3.92.84a3.11,3.11,0,0,0,2-1.33,3.23,3.23,0,0,0,.22-1.31c0,.05-.14,0-.26-.08L33.18,28h0c-.44-.22-2.06-1-2.33-1.11a.85.85,0,0,0-.21-.06,13.31,13.31,0,0,1-1.14,1.47,1,1,0,0,1-1.36.2,10,10,0,0,1-5-4.35,1,1,0,0,1,.28-1.36,3.84,3.84,0,0,0,.73-1s0,0,0-.17-.33-.79-.74-1.79l-.35-.84a.73.73,0,0,0-.17-.29H22.1ZM25.5,51A25.5,25.5,0,1,1,51,25.5,25.53,25.53,0,0,1,25.5,51Zm0-50A24.5,24.5,0,1,0,50,25.5,24.53,24.53,0,0,0,25.5,1Z"></path></svg>
          <div class="informative-oferta">
            <span>Ainda em dúvida? Fale com a gente! :)</span>
            <p>Estamos aqui para te guiar na escolha do certificado ideal para as suas necessidades!</p>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide class="informative-swiper-slide">
        <router-link to="#" class="router-slide router-house-laptop">
          <!--<i class="bi bi-house"></i>
          <i class="bi bi-laptop"></i>-->
          <svg class="icon-inline icon-5x svg-icon-text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path d="M23.88,25.45a.51.51,0,0,0,.5-.5V23.06a1.32,1.32,0,0,0-1.32-1.31H19.81a1.31,1.31,0,0,0-1.31,1.31v3.25a1.31,1.31,0,0,0,1.31,1.31h3.25a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H19.81a.31.31,0,0,1-.31-.31V23.06a.31.31,0,0,1,.31-.31h3.25a.32.32,0,0,1,.32.31V25A.5.5,0,0,0,23.88,25.45Z
            M23.56,33.62a.5.5,0,0,0-.5-.5H14.12A1.12,1.12,0,0,1,13,32V20l7.58-6.71a1.6,1.6,0,0,1,.86-.33,1.6,1.6,0,0,1,.86.33l4.49,4a.51.51,0,0,0,.54.08.5.5,0,0,0,.29-.46v-2a.33.33,0,0,1,.32-.32h1.62a.33.33,0,0,1,.32.32v4.87a.53.53,0,0,0,.16.38l3,2.66a.51.51,0,0,0,.71-.05.49.49,0,0,0,0-.7l-2.83-2.51V14.94a1.32,1.32,0,0,0-1.32-1.32H27.94a1.32,1.32,0,0,0-1.32,1.32v.88L23,12.58a2.28,2.28,0,0,0-3,0L9.16,22.1a.51.51,0,0,0,0,.71.51.51,0,0,0,.7,0L12,20.92V32a2.12,2.12,0,0,0,2.12,2.12h8.94A.5.5,0,0,0,23.56,33.62Z
            M41.21,35.25h-1.9V27.13a1.55,1.55,0,0,0-1.46-1.63H26.15a1.55,1.55,0,0,0-1.46,1.63v8.12h-1.9a.54.54,0,0,0-.54.54v.54a2.17,2.17,0,0,0,2.16,2.17H39.59a2.17,2.17,0,0,0,2.16-2.17v-.54A.54.54,0,0,0,41.21,35.25ZM25.69,27.13c0-.34.21-.63.46-.63h11.7c.25,0,.46.29.46.63v8.12H25.69Zm15.06,9.2a1.18,1.18,0,0,1-1.16,1.17H24.42a1.18,1.18,0,0,1-1.17-1.17v-.08h17.5Z
            M25.5,0A25.5,25.5,0,1,0,51,25.5,25.53,25.53,0,0,0,25.5,0Zm0,50A24.5,24.5,0,1,1,50,25.5,24.53,24.53,0,0,1,25.5,50Z"></path></svg>
          <div class="informative-oferta">
            <span>Com a gente, os certificados são emitidos através de videoconferência ou presencial</span>
            <p>Você escolhe a melhor forma para sua comodidade.</p>
          </div>
        </router-link>
      </swiper-slide>
      <swiper-slide class="informative-swiper-slide">
        <router-link to="#" class="router-slide router-support">
          <!--<i class="bi bi-info-circle"></i>-->
          <svg class="icon-inline icon-5x svg-icon-text"  viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><title>ONE-xicons</title><path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1A23,23,0,1,0,47,24,23,23,0,0,0,24,1Z"/><path d="M29.5,32.5h-11a0.5,0.5,0,0,1,0-1h11A0.5,0.5,0,0,1,29.5,32.5Z"/><path d="M24,32a0.5,0.5,0,0,1-.5-0.5V23a0.5,0.5,0,0,0-.5-0.5H20.5a0.5,0.5,0,0,1,0-1H23A1.5,1.5,0,0,1,24.5,23v8.5A0.5,0.5,0,0,1,24,32Z"/><circle cx="23" cy="17" r="1.5"/><rect width="48" height="48" fill="none"/></svg>
          <div class="informative-oferta info-suporte">
            <span>Suporte</span>
            <p>Aqui você vai encontrar os programas para instalação do seu Certificado Digital.</p>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>  

  <div class="container">
    <h1>Destaques</h1>
    <div
      class="row d-flex justify-content-center align-items-center card-space"
      style="margin-left: 40px"
    >
      <div class="col rounded mb-3">
        <swiper
          ref="mySwiper"
          :slides-per-view="slidesPerViewValue"

          :loop="false"
          :freeMode="false"
          :resistanceRatio="0"
          :initial-slide="initialSlideValue"
          :watchOverflow="true"
          :allow-slide-next="allowSlideNext"
          :allow-slide-prev="allowSlidePrev"
          @slideChange="onSlideChange"
          @reachEnd="onReachEnd"
          @reachBeginning="onReachBeginning"
          
          class="swiper-container-full"
        >
          <swiper-slide
            v-for="(produto, index) in produtosCard"
            :key="index"
            :produto="produto"
            class="swiper-slide-item"
          >
            <div class="card h-100" @click="viewProduct(produto)" v-if="produto.id < 4">
              <div :class="produto.cardText === '' ? 'empty-text' : 'card-destaque'">
                {{ produto.cardText }}
              </div>
              <div
                    :class="
                      produto.cardHighlight === '' ? 'none' : 'card-highlight'
                    "
                  ></div>
              <div class="text-center">
                <img
                  class="card-img-top mx-auto"
                  :src="produto.image"
                  alt="Card image cap"
                />
              </div>
              <div class="card-body boder">
                <p class="card-text">{{ produto.name === 'Certificado Digital e-CNPJ A3 Sem mídia' ? 'Certificado Digital e-CNPJ A3' : produto.name }}</p>
                <h5 class="card-title" style="margin-bottom: -5px;">R$ {{ produto.price }}</h5>
                <span class="card-title">{{ produto.pix }}</span>
                <p class="card-text" style="margin-bottom: -5px;">
                  <small class="text-muted"
                    >ou <b>R$ {{ produto.priceInstallments }}</b> a prazo</small
                  >
                </p>
                <p style="margin-bottom: -5px;">
                  <small class="text-muted installments">{{ produto.installments }}</small>
                </p>
              </div>
              <div class="card-buttons-container">
                <div class="card-buttons">
                  <button
                    type="button"
                    data-mdb-button-init
                    data-mdb-ripple-init
                    class="btn btn-primary btn-lg btn-block me-5"
                  >
                    <i class="bi bi-eye"></i>
                    Espiar
                  </button>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import SearchBarVue from '@/components/SearchBar.vue';
import ProductItemVue from "@/components/ProductItem.vue";
import Navbar from "../components/MainNavbar.vue";
import { mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from 'swiper/modules';

export default {
  name: "HomeView",
  components: {
    Navbar,
    //SearchBarVue,
    ProductItemVue,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      filtro: "",
      isVisible: false,
      spaceBetweenValue: -700,
      slidesPerViewValue: 3,
      slidesPerViewBanner: 4,
      initialSlideValue: 0,
      allowSlideNext: true,
      allowSlidePrev: true,
      swiperInstance: null,
      modules: [Pagination],
      windowWidth: window.innerWidth,
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
    };
  },
  computed: {
    /*produtosFiltrados() {
      if (!this.filtro) {
        return [];
      }
      return this.produtos.filter((produto) =>
        produto.name.toLowerCase().includes(this.filtro.toLowerCase())
      );
    }*/
    ...mapGetters(["produtosFiltrados"]),
    ...mapGetters(["produtosCard"]),
  },
  methods: {
    ...mapActions(["clearFiltro"]),
    viewProduct(produto) {
      this.$router.push({
        name: "ProdutoDetail",
        params: { id: produto.id },
        query: {
          name: produto.name,
          price: produto.price,
          pix: produto.pix,
          image: produto.image,
          priceInstallments: produto.priceInstallments,
          installments: produto.installments,
        },
      });
    },
    atualizarFiltro(novoFiltro) {
      this.filtro = novoFiltro;
      if (novoFiltro) {
        document.querySelector(".product-list-container").style.marginTop = "80px";
      } else {
        document.querySelector(".product-list-container").style.marginTop = "140px";
      }
    },
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    handleResizeBannerInformation() {
      if (window.innerWidth <= 390) {
        this.slidesPerViewBanner = 1;
      }
      else if (window.innerWidth <= 568) {
        this.slidesPerViewBanner = 1;
      }
      else if (window.innerWidth <= 768) {
        this.slidesPerViewBanner = 2;
      }
      else {
        this.slidesPerViewBanner = 4;
      }
      this.updateSwiper(); // Atualiza o Swiper após redimensionar
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      // Ajusta o valor de spaceBetween baseado na resolução da tela
      if (window.innerWidth <= 390) {
        this.slidesPerViewValue = 1.5;
      } else if (window.innerWidth <= 768) {
        this.spaceBetweenValue = 10;
        this.slidesPerViewValue = 3;
      } else if (window.innerWidth <= 991) {
        this.spaceBetweenValue = 20;
        this.slidesPerViewValue = 3;
      } else {
        this.spaceBetweenValue = 30;
        this.slidesPerViewValue = 3;
      }
      this.updateSwiper(); // Atualiza o Swiper após redimensionar
    },
    updateSwiper() {
      if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
        this.$refs.mySwiper.swiper.update(); // Força a atualização do Swiper
      }
    },
    onSlideChange() {
      const swiper = this.$refs.mySwiper?.swiper; // Verifica se o swiper está definido
      if (swiper) {
        this.allowSlideNext = !swiper.isEnd;  // Desabilita o próximo slide se estiver no fim
        this.allowSlidePrev = !swiper.isBeginning;  // Desabilita o slide anterior se estiver no início
      }
    },
    onReachEnd() {
      const swiper = this.$refs.mySwiper?.swiper; // Verifica se o swiper está definido
      if (swiper) {
        this.allowSlideNext = false;  // Quando alcançar o final, desabilite o próximo
      }
    },
    onReachBeginning() {
      const swiper = this.$refs.mySwiper?.swiper; // Verifica se o swiper está definido
      if (swiper) {
        this.allowSlidePrev = false;  // Quando alcançar o início, desabilite o anterior
      }
    },
  },
  mounted() {
    this.navBarAnimation();
    this.navBarAnimation991px();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize", this.handleResizeBannerInformation);
    this.handleResizeBannerInformation();
  },
  onMounted() {
    this.$nextTick(() => {
      if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
        this.swiperInstance = this.$refs.mySwiper.swiper;
        this.updateSwiper(); // Força a atualização quando o Swiper estiver montado
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.handleResizeBannerInformation);
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 11.875rem;
  padding-right: 5rem;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 10.625rem;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-top: 6.25rem;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
  margin-bottom: 1.875rem;
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
}

.carousel-inner {
  width: 100vw;
}

.carousel-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#carouselExampleIndicators {
  margin-top: 9.125rem;
  cursor: pointer;
}

.empty-text {
  height: 3.063rem;
}

.card-space {
  display: flex;
  gap: 18.75rem;
}

.swiper {
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}

.swiper-slide {
  width: 39.375rem;
  height: 39.375rem;
}

.card {
  position: absolute;
  cursor: pointer;
  border: 1px solid rgba(44, 62, 80, 0.1);
  width: 17rem;
  transition: box-shadow 0.3s;
  border-radius: 15px;
  overflow: hidden;
  height: 65% !important;
  transition: 0.5s;
}

.card:hover {
  height: 70% !important;
  box-shadow: 0 -8px 8px rgba(0, 0, 0, 0.1);
}

.card-buttons-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: transparent;
  display: none;
}

.card:hover .card-buttons-container {
  display: block;
}
.card-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}

.card-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2.5rem;
  border: none;
  border-radius: 0.25rem;
  width: 35%;
  height: 35px;
  gap: 0.313rem;
  cursor: pointer;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 1.25rem;
  transition: background-color 0.3s;
}

.card-destaque {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.313rem 0.313rem;
  border-radius: 15px;
  border: 1px solid #f7f7f8;
  background-color: green;
  color: #ffff;
  width: 35%;
  height: 1.875rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.5rem;
  margin: 0.625rem 0.625rem;
}

.card-highlight::before {
  content: "Mídia externa não inclusa";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #192750;
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 0 5px 0 8px;
}

.text-center {
  border-bottom: 0.313rem solid #0a67a8;
}

span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #0a67a8;
}

b {
  font-weight: bold
}

.installments {
  font-weight: bold;
}

.card img {
  margin-top: -20px;
  margin-bottom: -20px;
  width: 90%;
  height: auto;
  object-fit: cover;
}

.informative-swiper {
  width: 100vw !important;
  margin-left: 16px;
  background-color: #e9ebed;
  overflow: hidden;
}

.informative-swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12.5rem;
  margin-left: 1rem;
}

.router-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.svg-icon-text {
  width: 100%;
}

.informative-oferta {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding-right: 3.125rem;
}

.informative-oferta span {
  font-size: 0.938rem;
  font-family: "Montserrat", sans-serif;
  word-break: break-word;
  overflow-wrap: break-word;
  color: #2c3e50;
  max-width: 15rem;
}

.informative-oferta p {
  font-size: 0.813rem;
  font-family: "Montserrat", sans-serif;
  color: #2c3e50;
  word-break: break-word;
  overflow-wrap: break-word;
  width: 12rem;
}

.informative-oferta span:hover {
  color: #455c74;
}

.informative-oferta p:hover {
  color: #455c74;
}

@media (max-width: 991px) {
  .swiper {
    width: 100%;
    margin: 0 auto;
  }

  .swiper-slide-item {
    width: calc((100vw - 40px) / 2); /* 2 slides visíveis */
    margin-right: 4rem;
    object-fit: cover;
  }

  .card-space {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .card-destaque {
    width: 30%;
  }

  .empty-text {
    height: 3.125rem;
  }

  .text-center img {
    max-width: 100%;
  }

  .product-list {
    margin: 3.438rem 0 0 8.125rem;
  }

  .product-list-container {
    margin-top: 6.875rem;
  }

  .product-list-container.sticky {
    margin-top: 3.125rem;
  }

  .informative-swiper {
    display: flex;
    width: 100%;
    margin-left: 10px !important;
  }

  .informative-swiper-slide {
    width: 100%;
    height: auto;
  }

  .informative-swiper-slide .router-slide {
    display: flex;
    flex-direction: column;
    width: 100%;    
  }

  .informative-oferta {
    max-width: 80%;
  }

  .svg-icon-text {
    max-width: 30%;
  }
  
}

@media (max-width: 768px) {
  .product-list {
    width: 100%;
    margin: 4.5rem 0 0 6.25rem;
  }

  .carousel-item img {
    width: 100%;
    height: auto;
  }

  .card img {
    padding-top: 0.313rem;
  }

  .swiper {
    width: 100%;
    margin: 0 auto;
  }

  .swiper-slide-item {
    width: 38% !important;
    object-fit: cover;
  }

  .empty-text {
    height: 3.063rem;
    padding-bottom: 3.125rem;
  }

  .card {
    width: 13.75rem;
    height: 25.625rem !important;
  }

  .card:hover {
    height: 70% !important;
  }

  .card-destaque {
    width: 40% !important;
  }

  .card-highlight::before {
    max-width: 100%;
    padding: 0.438rem 1.25rem;
    font-size: 0.625rem;
  }

  .informative-swiper {
    display: flex;
    width: 100%;
    height: auto;
  }

  .informative-swiper-slide .router-slide {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
  }

  .svg-icon-text {
    max-width: 25%;
  }
}

@media (max-width: 568px) {
  .empty-text {
    height: 3rem;
    padding-bottom: 3.063rem;
  }

  .card img {
    padding-bottom: 0.625rem;
  }
  
  .card {
    width: 13.75rem;
    height: 26.25rem !important;
  }

  .card:hover {
    height: 71% !important;
  }

  .card-highlight::before {
    max-width: 100%;
    padding: 0.438rem 1.25rem;
    font-size: 0.625rem;
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .swiper-slide {
    width: 48% !important;    
    margin: 0 1.875rem 0 0;
    object-fit: cover;
  }

  .informative-swiper {
    width: 100%;
  }

  .informative-swiper-slide {
    width: 100% !important;
    height: auto;
  }

  .informative-swiper-slide .router-slide {
    width: 100%;
    margin: 0 auto;
    padding: 2.5rem;
  }

  .router-percent {
    margin-left: 3.125rem !important;
  }

  .router-support {
    margin-right: 3.125rem !important;
  }

  .svg-icon-text {
    max-width: 20%;
  }

  .card-buttons button {
    width: 40%;
  }

}

@media (max-width: 450px) {
  .card {
    width: 13.75rem;
    height: 25.625rem !important;
  }
  
  .card-highlight::before {
    max-width: 100%;
    padding: 0.5rem 1.25rem;
    font-size: 0.5rem;
  }

  .card-destaque {
    width: 40% !important;
    height: auto;
  }

  .card:hover {
    height: 72% !important;
  }

  .swiper-slide {   
    margin: 0 5.375rem 0 0;
  }

  .informative-swiper-slide {
    width: 85% !important;
    height: auto;
  }
}


</style>
