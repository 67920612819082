<template>
  <div class="app-container">
    <NavBar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
          @click="refreshView"
        />
      </div>
    </div>
  </div>
  <div class="container">
    <h2>Videochamada</h2>
    <h1>Requisitos para videoconferência</h1>
    <div class="req-video">
      <span class="req-video-paragraph1">
        <i class="bi bi-check-circle-fill" style="color: #0a67a8"></i>
        <p>
          Se já fez certificado digital presencialmente<br />a partir de 2018.
        </p>
      </span>
      <strong>ou</strong>
      <span class="req-video-paragraph1">
        <i class="bi bi-check-circle-fill" style="color: #0a67a8"></i>
        <p>
          Se possui carteira nacional de trânsito (CNH)<br />a partir de 2017.
        </p>
      </span>
    </div>
  </div>
  <div class="container-button">
    <button
      type="button"
      data-mdb-button-init
      data-mdb-ripple-init
      class="btn btn-primary btn-lg btn-block me-5"
    >
      <a href="https://api.whatsapp.com/send?l=pt&phone=5512996267699&text=Novo%20Contato%20via%20Site" target="_blank">
        Dúvidas? Chame no Whatsapp
      </a>
      <i class="bi bi-whatsapp"></i>
    </button>
  </div>
</template>

<script>
import NavBar from "../components/MainNavbar.vue";
import ProductItemVue from "../components/ProductItem.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "RequisitosView",
  data() {
    return {
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
    };
  },
  components: {
    NavBar,
    ProductItemVue,
  },
  computed: {
    ...mapGetters(["produtosFiltrados"]),
  },
  methods: {
    ...mapActions(["clearFiltro"]),
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
  },
  mounted() {
    this.navBarAnimation();
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
  margin-top: 11.25rem;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #282d31;
  margin-top: 3.125rem;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 11.875rem;
  padding-right: 5rem;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 10.625rem;
  transition: 0.8s;
}

.product-list {
  position: fixed;
  width: 48%;
}

.req-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 3.125rem;
}

.req-video span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.req-video i {
  margin: 0 0.625rem 2.5rem 0;
}

.req-video p {
  text-align: justify;
  color: #475660;
}

.req-video-paragraph1 {
  margin-right: 3.125rem;
}

i {
  margin-left: 3.125rem;
}

strong {
  padding-right: 3.125rem;
  font-weight: bold;
}

.container-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.125rem;
  margin-bottom: 12.5rem;
}

button {
  width: 22%;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding: 0.625rem;
}

button i {
  margin-left: 0.313rem;
}

a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 991px) {
  .product-list-container {
    margin: 13.75rem 0 0 3.125rem;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .req-video p {
    font-size: 0.625rem;
  }

  .container-button {
    max-width: 100%;
  }

  button {
    width: 50%;
    font-size: 0.625rem;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }

  .product-list {
    width: 6.25rem;
    margin-left: 6.25rem;
  }

  .product-list-container {
    margin-top: 15rem;
  }

  .product-list-container.sticky {
    margin-top: 11.25rem;
  }
}

@media (max-width: 568px) {
  .product-list-container {
    margin: 16.25rem 6.25rem 0 0;
  }

  .product-list-container.sticky {
    margin-top: 12.5rem;
  }

  .req-video {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .req-video i {
    margin: 0 0.625rem 1.25rem 0;
  }

  strong {
    padding-bottom: 1.25rem;
  }
}
</style>