// src/store.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    filtro: '',
    isInputFilter: false,
    produtos: [
      {
        id: 1,
        name: "Certificado Digital e-CNPJ A1",
        price: "177,30",
        pix: "NO PIX",
        image: "/img/e-cnpj-A1.webp",
        priceInstallments: "197,00",
        installments: "em até 3x de R$ 65,67 sem juros",
        cardText: "MAIS VENDIDO",
        cardHighlight: ""
      },
      {
        id: 2,
        name: "Certificado Digital e-CNPJ A3 Sem mídia",
        price: "306,00",
        pix: "NO PIX",
        image: "/img/e-cnpj-A3.webp",
        priceInstallments: "340,00",
        installments: "em até 3x de R$ 113,33 sem juros",
        cardText: "",
        cardHighlight: "Mídia externa não inclusa"
      },
      {
        id: 3,
        name: "Certificado Digital e-CPF A1",
        price: "126,00",
        pix: "NO PIX",
        image: "/img/e-CPF-A1.webp",
        priceInstallments: "140,00",
        installments: "em até 3x de R$ 46,67 sem juros",
        cardText: "",
        cardHighlight: ""
      },
      {
        id: 4,
        name: "Certificado Digital e-CPF A3 - Sem mídia",
        price: "216,00",
        pix: "NO PIX",
        image: "/img/e-CPF-A3.webp",
        priceInstallments: "240,00",
        installments: "em até 3x de R$ 80,00 sem juros",
        cardText: "",
        cardHighlight: "Mídia externa não inclusa"
      },
      {
        id: 5,
        name: "Certificado Digital MEI A1",
        price: "177,30",
        pix: "NO PIX",
        image: "/img/MEI-A1.webp",
        priceInstallments: "197,00",
        installments: "em até 3x de R$ 65,67 sem juros",
        cardText: "",
        cardHighlight: ""
      },
    ]
  },
  mutations: {
    setFiltro(state, {filtro, isInputFilter}) {
      state.filtro = filtro;
      state.isInputFilter = isInputFilter;
    },
    clearFiltro(state) {
      state.filtro = '';
      state.isInputFilter = false;
    }
  },
  actions: {
    updateFiltro({ commit }, { filtro, isInputFilter }) {
      commit('setFiltro', { filtro, isInputFilter });
    },
    clearFiltro({ commit }) {
      commit('clearFiltro');
    }
  },
  getters: {
    filtro: state => state.filtro,
    produtosFiltrados: state => {
      if (!state.filtro) {
        return [];
      }
      return state.produtos.filter(produto =>
        produto.name.toLowerCase().includes(state.filtro.toLowerCase())
      );
    },
    produtosCard: state => {
      return state.produtos.filter(produto =>
        produto.name.toLowerCase().includes(state.filtro.toLowerCase())
      );
    },
    isInputFilter: state => !!state.filtro,
  }
});

export default store;
