<template>
  <div class="app-container">
    <NavBar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
        />
      </div>
    </div>
  </div>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
  />
  <div class="container">
    <div class="content">
      <div class="left-side">
        <div class="address details">
          <i class="fas fa-map-marker-alt"></i>
          <div class="topic">Endereço</div>
          <div class="text-one">Rua Marabá, 110</div>
          <div class="text-two">Parque Industrial, SJCampos SP, 12235-830</div>
        </div>
        <div class="phone details">
          <i class="fas fa-phone-alt"></i>
          <div class="topic">Telefone</div>
          <div class="text-one">+55 12 3938 3633</div>
          <div class="text-two">+55 12 99626 7699</div>
        </div>
        <div class="email details">
          <i class="fas fa-envelope"></i>
          <div class="topic">Email</div>
          <div class="text-one">contato@cdconectec.com.br</div>
          <div class="text-two">anacarolina@cdconectec.com.br</div>
        </div>
      </div>
      <div class="right-side">
        <div class="topic-text">Entre em contato conosco</div>
        <p style="text-align: left">
          Será um prazer ajudá-lo! Nossa equipe está à disposição para responder
          suas perguntas e fornecer o suporte necessário. Não hesite em nos
          enviar uma mensagem.
        </p>
        <form @submit.prevent="sendEmail">
          <div class="input-box">
            <input
              type="text"
              v-model="formData.name"
              placeholder="Digite seu nome"
              required
            />
          </div>
          <div class="input-box">
            <input
              type="number"
              v-model="formData.phone"
              placeholder="Digite seu telefone"
              required
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              v-model="formData.email"
              placeholder="Digite seu email"
              required
            />
          </div>
          <div class="input-box message-box">
            <textarea
              v-model="formData.message"
              placeholder="Digite sua mensagem"
            ></textarea>
          </div>
          <div class="button">
            <input type="submit" value="Enviar" />
          </div>
        </form>
      </div>
    </div>
    <MainLoading v-show="isActive" />
    <AlertBox :msg="msg" v-show="msg" />
  </div>

  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.0048368848593!2d-45.914852385363965!3d-23.242911055793485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc355cc3235ca3%3A0xb848c2c1bef98ce0!2sR. Marabá, 110 - Parque Industrial, São José dos Campos - SP, 12235-830!5e0!3m2!1spt-BR!2sbr!4v1471364761158"
      width="100%"
      height="330"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import MainLoading from "@/components/MainLoading.vue";
import AlertBox from "@/components/AlertBox.vue";
import NavBar from "../components/MainNavbar.vue";
import ProductItemVue from "../components/ProductItem.vue";
import { mapGetters } from "vuex";
import { mapActions } from 'vuex';

export default {
  name: "SuporteView",
  components: {
    MainLoading,
    AlertBox,
    NavBar,
    ProductItemVue,
  },
  data() {
    return {
      isActive: false,
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
      msg: "",
      formData: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters(["produtosFiltrados"]),
  },
  created() {
    emailjs.init("DMjHrJUTB8J6AdLiS"); // Iniciando o EmailJS aqui
  },
  methods: {
    ...mapActions(['clearFiltro']),
    sendEmail() {
      this.isActive = !this.isActive;
      emailjs
        .send("service_gkpealj", "template_jp4ovpo", this.formData)
        .then((response) => {
          console.log(
            "E-mail enviado com sucesso!",
            response.status,
            response.text
          );
          this.msg = `E-mail enviado com sucesso!`;
          this.isActive = !this.isActive;
          setTimeout(() => {
            this.msg = "";
            this.limparCampos();
          }, 3000);
        })
        .catch((error) => {
          console.log("Ocorreu um erro ao enviar o e-mail:", error);
          this.msg = `Ocorreu um erro ao enviar o e-mail:\n ${error}`;
          this.isActive = !this.isActive;
          setTimeout(() => {
            this.msg = "";
            this.limparCampos();
          }, 3000);
        });
    },
    limparCampos() {
      this.formData.name = "";
      this.formData.phone = "";
      this.formData.email = "";
      this.formData.message = "";
    },
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
  },
  mounted() {
    this.navBarAnimation();
    this.navBarAnimation991px();
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
/* Google Font CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 180px;
  margin-left: -80px;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 170px;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

.container {
  max-width: 85%;
  margin: 0 auto;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-top: 170px;
  margin-bottom: 50px;
}
.container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before {
  content: "";
  position: absolute;
  height: 80%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #cfcccc;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #0a67a8;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #767679;
}

.container .content .right-side {
  width: 75%;
  margin-left: 75px;
}

.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #0a67a8;
  text-align: left;
}

.right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: flex;
  margin-top: 20px;
}
.right-side .button input[type="submit"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #0a67a8;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="submit"]:hover {
  background: #b9daf1;
  color: black;
}

iframe {
  width: 85%;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .product-list-container {
        margin: 215px 0 0 50px;
    }

  .product-list-container.sticky {
      margin-top: 155px;
  }  
}

@media (max-width: 950px) {
  .container {
    width: 100%;
    padding: 30px 40px 40px 35px;
  }
  .container .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}
@media (max-width: 820px) {
  .container {
    margin: 40px 0;
    height: 100%;
    margin-top: 150px;
  }
  .container .content {
    flex-direction: column-reverse;
  }
  .container .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .container .content .left-side::before {
    display: none;
  }
  .container .content .right-side {
    width: 100%;
    margin-left: 0;
  }
  iframe {
    max-width: 100vh;
    margin-bottom: 50px;
  }
  .product-list {
    width: 100%;
    margin: 15px 0 0 -30px;
  }
}

@media (max-width: 768px) {
  .product-list {
    margin-top: 22px;
  }
  
  .container {
    max-width: 100%;
  }
}
</style>