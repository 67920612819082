<template>
  <div class="app-container">
    <Navbar :logo="logo_src" :alt="app_name" />
    <div class="product-list-container">
      <div class="product-list bg-light" style="border-radius: 10px">
        <ProductItemVue
          v-for="(produto, index) in produtosFiltrados"
          :key="index"
          :produto="produto"
        />
      </div>
    </div>
  </div>
  <section class="container">
    <h1>Quem Somos</h1>
    <div class="page-header">
      <span>Olá, somos a Conectec</span>
      <p>Vamos facilitar a sua escolha na certificação digital &#128521;</p>
    </div>
    <div class="container-page-title">
      <div class="page-title-one">
        <div class="page-title-image-one">
          <i class="bi bi-globe"></i>
          <i class="bi bi-people-fill"></i>
        </div>
        <span>Online ou Presencial</span>
        <p>
          Seu certificado emitido por video chamada<br>ou presencial no horário que estiver disponível<br>
            na agenda de atendimento que é das 08:30 às<br>
          17:30 hrs de Segunda a Sexta-feira.
        </p>
      </div>
      <div class="page-title-one">
        <div class="page-title-image-one">
          <i class="bi bi-fingerprint"></i>
        </div>
        <span>Liberdade é se sentir seguro</span>
        <p>
          A praticidade te move e sabemos<br>disso. Conduzimos a emissão de<br>
          uma forma simples, que garanta<br>a sua segurança. Sem firulas.
        </p>
      </div>
      <div class="page-title-one">
        <div class="page-title-image-one">
          <i class="bi bi-clock-history"></i>
        </div>
        <span>Valorizamos seu tempo</span>
        <p>
          Atendimento 78% mais rápido<br>do que o mercado, sem rodeios.<br>
          É realmente super fácil de obter<br>seu certificado digital.
        </p>
      </div>
      <div class="page-title-one">
        <div class="page-title-image-one">
          <i class="bi bi-people"></i>
        </div>
        <span>Para todos</span>
        <p>
          Ajudamos empreendedores,<br>profissionais autônomos,<br>
          estudantes, entre tantos, de<br>todo o Brasil.
        </p>
      </div>
    </div>
    <!--<div class="container-sobrenos">
      <img src="/img/sobrenos.png" alt="" />
    </div>

    <div class="container-atendentes">
      <span>
        <img src="/img/carol.png" alt="" />
      </span>
      <span>
        <img src="/img/vitor.png" alt="" />
      </span>
    </div> -->

    <div class="container-descriptition">
      <span>
        <h2 class="sublinhado">Simplificar e ir<br />direto ao ponto</h2>
        <p>
          Acreditamos que podemos romper<br />
          barreiras e simplificar processos<br />
          sem intermediários.
        </p>
      </span>
      <span>
        <h2 class="sublinhado">Promover segurança<br />e independência</h2>
        <p>
          Acreditamos que a independência provém<br />
          do sentimento de se sentir resguardado.<br />
          Com a Conectec, você vai saber onde<br />
          está pisando.
        </p>
      </span>
    </div>
    <div class="container-descriptition-two">
      <span>
        <h2 class="sublinhado">Facilitar escolhas<br />com praticidade</h2>
        <p>
          Acreditamos que podemos criar<br />
          maneiras e meios para a escolha.<br />
        </p>
      </span>
      <span>
        <h2 class="sublinhado">Encorajar seu<br />protagonismo</h2>
        <p>
          Acreditamos na experiência de guiar<br />
          o cliente para uma trajetória cada<br />
          vez mais segura e consciente.<br />
        </p>
      </span>
    </div>
    <div class="sobre-nos">
      <p>
        Somos uma empresa do grupo Droga Bella há mais de 5 anos<br>no mercado, viabilizando sua identidade no mundo digital.
      </p>
    </div>
    <a href="https://api.whatsapp.com/send?l=pt&phone=5512996267699&text=Novo%20Contato%20via%20Site" target="_blank">
      <div class="container-match">
        <p>Deu match com a gente? Inicie já a sua certificação digital!</p>
        <span>Solicite seu certificado aqui!</span>
        <img src="/img/conectec_logo.svg" alt="">
      </div>
    </a>
  </section>
</template>

<script>
import Navbar from "../components/MainNavbar.vue";
import ProductItemVue from "../components/ProductItem.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "SobreNos",
  components: {
    Navbar,
    ProductItemVue,
  },
  data() {
    return {
      logo_src: "/img/conectec_logo.svg",
      app_name: "Conectec",
    };
  },
  computed: {
    ...mapGetters(["produtosFiltrados"]),
  },
  methods: {
    ...mapActions(["clearFiltro"]),
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    navBarAnimation991px() {
      window.addEventListener("scroll", function () {
        var nav = document.querySelector(".product-list-container");
        nav.classList.toggle("sticky", window.scrollY > 0);
      });
    },
  },
  mounted() {
    this.navBarAnimation();
    this.navBarAnimation991px();
  },
  beforeRouteLeave(to, from, next) {
    this.clearFiltro();
    next();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.product-list-container {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 190px;
  margin-left: -80px;
  z-index: 9999;
}

.product-list-container.sticky {
  margin-top: 170px;
  transition: 0.7s;
}

.product-list {
  position: fixed;
  width: 48%;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #0a67a8;
  margin-top: 180px;
}

.container-page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
}

.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0a67a8;
  max-width: 100%;
  margin: 10px 150px 20px 150px;
  border-radius: 15px;
}

.page-header span {
  font-family: "Noto Serif";
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}

.page-header p {
  font-family: "Noto Serif";
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.page-title-one {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.page-title-image-one i {
  font-size: 50px;
  color: #1d2945;
}

.page-title-one span {
  font-weight: bold;
  color: #1d2945;
  margin-bottom: 15px;
}

.page-title-one p {
  font-size: 12px;
  color: #485166;
}

.sobre-nos {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a67a8;
  width: 100%;
  height: 9.375rem;
  margin-top: 50px;
  border-radius: 15px;
}

.sobre-nos p {
  font-family: "Noto Serif";
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.container-images {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-sobrenos img {
  max-width: 50%;
  border-radius: 15%;
  margin-top: 150px;
}

.container-atendentes {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 50px;
}

.container-atendentes span img {
  max-width: 70%;
  border-radius: 25%;
}

.container-descriptition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 100px;
  gap: 150px;
}

.container-descriptition h2 {
  font-family: "Noto Serif";
}

.container-descriptition p {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-top: 15px;
}

.container-descriptition-two {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 100px;
  gap: 150px;
}

.container-descriptition-two h2 {
  font-family: "Noto Serif";
}

.container-descriptition-two p {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-top: 15px;
}

a {
  text-decoration: none;
}

.container-match {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  cursor: pointer;
}

.container-match img {
  width: 200px;
}

.container-match p {
  font-family: "Noto Serif";
  font-size: 28px;
  font-weight: 700;
  color: #1d2945;
  margin-top: 10px;
}

.container-match span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Serif";
  font-size: 28px;
  font-weight: 300;
  color: #fff;
  background-color: #1d2945;
  border-radius: 20px;
  width: 50%;
  height: 60px;
  margin-top: 10px;
}

.sublinhado {
  font-size: 2rem;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.sublinhado::after {
  content: "";
  position: absolute;
  width: 120%;
  height: 4px; /* Ajuste a espessura aqui */
  background: linear-gradient(to right, rgb(0, 0, 19), transparent);
  bottom: 0;
  left: 0;
  transform: translateY(6px); /* Ajuste a distância do sublinhado aqui */
}

@media (max-width: 991px) {
  .product-list-container {
    margin-top: 220px;
  }

  .product-list {
    margin-left: 150px;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .product-list-container.sticky {
    margin-top: 160px;
  }

  .page-header {
    width: 100%;
    margin: 0 auto;
  }

  .container-page-title {
    max-width: 100%;
    gap: 30px;
  }

  .sobre-nos {
    max-width: 100%;
  }

  .sobre-nos p {
    font-size: 22px;
  }

  .container-match {
    max-width: 100%;
  }

  .container-match span {
    width: 70vh;
  }

  .container-match img {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }

  .product-list-container {
    margin-top: 240px;
  }

  .product-list {
    width: 100%;
    margin-left: 100px;
  }

  .product-list-container.sticky {
    margin-top: 180px;
  }

  .container-page-title {
    max-width: 100%;
  }
}

@media (max-width: 568px) {
  .product-list {
    margin-top: 10px;
  }
  .page-header {
    max-width: 100%;
  }

  .page-header span {
    font-size: 25px;
  }

  .page-header p {
    font-size: 16px;
  }

  .container-page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
  }

  .container-descriptition {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-descriptition-two {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sobre-nos {
    max-width: 100%;
    height: 6.25rem;
  }

  .sobre-nos p {
    font-size: 15px;
  }

  .container-match span {
    max-width: 100%;
    font-size: 25px;
  }
}
</style>