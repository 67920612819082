<template>
  <div class="">
    <ModalMenu />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    />
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light fixed-top rounded"
      aria-label="Eleventh navbar example"
    >
      <div class="container-fluid d-flex align-items-center" id="container-nav">
        <div class="logo">
          <router-link to="/">
            <img
              :src="logo"
              class="img-fluid me-5 mb-3"
              :alt="alt"
              id="logo-img"
            />
          </router-link>
        </div>
        <form class="form-search mt-1 form-group form-control-lg w-50">
          <input
            class="form-control"
            type="text"
            v-model="filtro"
            placeholder="O que você está buscando?"
            @input="onInputChange"
            aria-label="Search"
          />
        </form>
        
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="modal" data-target="#ModalMenu"
          aria-controls="navbarsExample09"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="button-navbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse bg-light mt-5 z-1 position-static"
          id="navbarsExample09"
        >
          <ul class="navbar-nav me-auto mb-2" id="navbarUl">
            <li
              class="nav-item dropdown d-flex justify-content-center align-content-center mb-4 pe-4"
            >
              <router-link to="#" @mouseover="showDropdown" @mouseleave="hideDropdown">
                <svg
                  height="40"
                  width="40"
                  fill="#0a67a8"
                  stroke="#0a67a8"
                  stroke-width="0.2"
                  style="margin-right: 5px"
                  xmlns="http://www.w3.org/2000/svg"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <path
                    d="M32.5 10H25V3.5C25 1.6 23.4 0 21.5 0h-18A3.5 3.5 0 0 0 0 3.5v10C0 15.4 1.6 17 3.5 17h2.9v3.5c0 .2.1.4.3.4l.2.1.3-.1 5.8-3.7v6.3c0 1.9 1.6 3.5 3.5 3.5h6.1l6.2 3.9.3.1.2-.1c.2-.1.3-.3.3-.4V27h2.9c1.9 0 3.5-1.6 3.5-3.5v-10c0-1.9-1.6-3.5-3.5-3.5zM7.4 19.6v-3.1c0-.3-.2-.5-.5-.5H3.5A2.5 2.5 0 0 1 1 13.5v-10C1 2.1 2.1 1 3.5 1h18C22.9 1 24 2.1 24 3.5v10c0 1.4-1.1 2.5-2.5 2.5h-8.3l-.3.1-5.5 3.5zM35 23.5c0 1.4-1.1 2.5-2.5 2.5h-3.4c-.3 0-.5.2-.5.5v3.1L23 26.1l-.2-.1h-6.3a2.5 2.5 0 0 1-2.5-2.5V17h7.5c1.9 0 3.5-1.6 3.5-3.5V11h7.5c1.4 0 2.5 1.1 2.5 2.5v10z"
                  />
                </svg>
                <p
                  @mouseover="showDropdown" @mouseleave="hideDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="font-size: 14px; margin-top: -6px; font-weight: 500; font-family: Montserrat"
                  id="p-atendimento"
                >
                  Atendimento
                </p>
                <ul
                  class="dropdown-menu bg-light me-5"
                  aria-labelledby="dropdown09"
                  id="dropdownUL"
                  v-show="isDropdownVisible"
                >
                  <li>
                    <img src="/img/icon-phone.svg" alt="" id="phone-icon" />(12)
                    3938-3633
                  </li>
                  <li>
                    <img
                      src="/img/whatsapp-svg.svg"
                      alt=""
                      id="whats-icon"
                    />(12) 99767-8747
                  </li>
                </ul>
                <!--<i class="material-symbols-outlined">call</i>-->
              </router-link>
            </li>
            <li class="nav-item pe-4">
              <router-link to="/produtos">
                <svg style="margin-top: -4px" xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 24 24" fill="none" stroke="#0a67a8" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-fingerprint"><path d="M12 10a2 2 0 0 0-2 2c0 1.02-.1 2.51-.26 4"/><path d="M14 13.12c0 2.38 0 6.38-1 8.88"/><path d="M17.29 21.02c.12-.6.43-2.3.5-3.02"/><path d="M2 12a10 10 0 0 1 18-6"/><path d="M2 16h.01"/><path d="M21.8 16c.2-2 .131-5.354 0-6"/><path d="M5 19.5C5.5 18 6 15 6 12a6 6 0 0 1 .34-2"/><path d="M8.65 22c.21-.66.45-1.32.57-2"/><path d="M9 6.8a6 6 0 0 1 9 5.2v2"/></svg>
                <p id="p-produtos" style="font-size: 14px; margin-top: 1px; font-weight: 500; font-family: Montserrat">Certificados</p>
              </router-link>
            </li>
            <li class="nav-item pe-4">
              <router-link to="/suporte">
                <svg
                  height="40"
                  width="40"
                  fill="#0a67a8"
                  stroke="#0a67a8"
                  stroke-width="0.1"
                  style="margin-left: 7px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 16.6c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8zm0-15.1c3.9 0 7 3.2 7 7s-3.1 7-7 7-7-3.2-7-7 3.1-7 7-7zM29.5 29c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-4.8-6.1-8.7-13.5-8.7S1.5 24.2 1.5 29c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-5.3 6.5-9.7 14.5-9.7s14.5 4.4 14.5 9.7z"
                  />
                </svg>
                <p style="font-size: 14px; margin-top: -6px; font-weight: 500; font-family: Montserrat">Contato</p>
              </router-link>
            </li>
            <!--<li class="nav-item pe-4">
              <router-link to="#">
                <i class="bi bi-person" style="font-size: 30px"></i>
                <p style="font-size: 14px; margin-top: -11px">Contato</p>
              </router-link>
            </li>-->
            <!--<li class="nav-item pe-4" id="list-product">
              <router-link to="/produtos">
                <i class="bi bi-shop" style="font-size: 30px"></i>
                <p style="font-size: 14px; margin-top: -9px">Produtos</p>
              </router-link>
            </li>-->
            <li class="nav-item pe-4" id="list-product">
              <router-link to="/sobrenos">
                <i class="bi bi-shop" style="font-size: 30px"></i>
                <p style="font-size: 14px; margin-top: -9px; font-weight: 500; font-family: Montserrat">Quem somos</p>
              </router-link>
            </li>
            <li class="nav-item pe-4" id="list-product">
              <router-link to="/duvidas">
                <i class="bi bi-question-circle" style="font-size: 30px"></i>
                <p style="font-size: 14px; margin-top: -9px; font-weight: 500; font-family: Montserrat">
                  Dúvidas? Nós te ajudamos
                </p>
              </router-link>
            </li>
            <li class="nav-item pe-4" id="list-product">
              <router-link to="/requisitos">
                <i class="bi bi-shop" style="font-size: 30px"></i>
                <p style="font-size: 14px; margin-top: -9px; font-weight: 500; font-family: Montserrat">
                  Requisitos para videochamada
                </p>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav class="navbar-bottom-links bg-light">
      <ul class="nav justify-content-center">
        <li class="nav-item pe-5">
          <router-link class="nav-router" to="/produtos/#"
            >Certificados</router-link
          >
        </li>
        <li class="nav-item pe-5">
          <router-link class="nav-router" to="/sobrenos"
            >Quem somos</router-link
          >
        </li>
        <li class="nav-item pe-5">
          <router-link class="nav-router" to="/duvidas"
            >Dúvidas? Nós te ajudamos!</router-link
          >
        </li>
        <li class="nav-item pe-3">
          <router-link class="nav-router" to="/requisitos"
            >Requisitos para videochamada</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ModalMenu from "@/components/ModalMenu.vue";

export default {
  name: "MainNavbar",
  props: ["logo", "alt"],
  components: {
    ModalMenu
  },
  data() {
    return {
      filtro: "",
      searchQuery: "",
      isMarginApplied: false,
      isDropdownVisible: false,
    };
  },
  computed: {
    ...mapState(["filtro"]),
    ...mapGetters(['isInputFilter'])
  },
  methods: {
    onInputChange(event) {
      this.$store.commit('setFiltro', { filtro: event.target.value, isInputFilter: true });
    },
    emitirFiltro() {
      this.$emit("updateFiltro", this.filtro);
    },
    ...mapActions(["updateFiltro"]),
    updateFilter() {
      this.updateFiltro(this.filtro);
    },
    navBarAnimation() {
      window.addEventListener("scroll", function () {
        var header = document.querySelector("nav");
        var nav = document.querySelector("#navbarsExample09");
        var navShow = document.querySelector(".navbar-bottom-links");
        var logo = document.querySelector("#logo-img");
        var button = this.document.querySelector("#button-navbar");
        header.classList.toggle("sticky", window.scrollY > 0);
        nav.classList.toggle("sticky", window.scrollY > 0);
        navShow.classList.toggle("sticky", window.scrollY > 0);
        logo.classList.toggle("sticky", window.scrollY > 0);
        button.classList.toggle("sticky", window.scrollY > 0);
      });
    },
    showDropdown() {
      this.isDropdownVisible = true;
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    },
  },
  mounted() {
    this.navBarAnimation();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: #0a67a8;
}

.form-search input::placeholder {
  color: #0a67a8;
  
}

#logo-img.sticky {
  margin-bottom: 20px;
  transition: 0.7s;
}

button.sticky {
  margin-top: 15px;
  transition: 0.7s;
}

nav.sticky {
  height: 15%;
  border-bottom: 0;
  transition: 0.7s;
}
#navbarsExample09.sticky {
  height: 20px;
  width: 15%;
  transition: 0.7s;
}

.navbar-bottom-links.sticky {
  display: none;
}

.container {
  max-width: 100%;
}

nav {
  height: 150px;
  border-bottom: 1px solid #cfcccc;
}

.navbar {
  width: 100%;
}

.container-fluid {
  max-width: 100%;
}

.nav-item {
  margin-left: 10px !important;
}

.navbar-bottom-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  margin-left: 6px;
  position: relative;
  top: 146px;
  padding-top: 18px;
  border-top: 1px solid #cfcccc;
  height: 60px;
}

.nav-router {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #0a67a8;
}

.navbar-bottom-links .nav-link {
  color: #000;
  font-weight: 500;
}

.navbar-bottom-links ul {
  width: 100vw;
  justify-content: center;
}

.navbar-bottom-links li {
  margin-right: 20px;
}

#navbarsExample09 {
  margin-left: 780px;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.logo img {
  width: 200px;
  height: 150px;
}

#whats-icon {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 10px;
}

#phone-icon {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 10px;
}

#dropdownUL {
  font-family: "Montserrat", sans-serif;
  border-color: #0a67a8;
  margin: -18px 0 0 -35px !important;
  padding: 5px;
}

#dropdownUL li {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.form-search {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: -40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-search input {
  height: 50px;
  margin-top: 10px;
  padding-left: 12px;
}

input::placeholder {
  color: #767679;
  font: 14px "Montserrat", arial, sans-serif;
}

#list-product {
  display: none;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }

  #container-nav button {
    margin-bottom: 160px;
  }

  .form-search input {
    position: absolute;
    width: 100vh;
    margin: 0 0 0 50px;
  }

  .logo img {
    max-width: 100%;
    max-height: 100%;
    margin-top: -130px;
  }

  button {
    padding: 5px 10px !important;
    margin: 50px 50px 0 0;
  }

  #navbarsExample09 {
    position: absolute;
    max-width: 45% !important;
    margin: 0 0 -200px 200px;
    padding-top: 15px !important;
    border-radius: 15px;
  }

  #navbarsExample09 p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100% !important;
    flex-direction: row !important;
    margin: 10px;
    font-size: 20px !important;
    border-top: 1px solid #0a67a8;
  }

  #navbarsExample09 #p-atendimento,
  svg {
    display: none;
  }

  .navbar-bottom-links {
    display: none;
  }

  #dropdownUL {
    width: 15%;
    height: 55px;
  }

  #dropdownUL li {
    font-size: 12px;
    margin-left: -120px;
    margin-bottom: -42px;
    margin-top: -20px;
  }

  #whats-icon {
    width: 105px;
    height: 105px;
    margin: 0 -30px -90px 80px;
  }

  #phone-icon {
    width: 105px;
    height: 105px;
    margin: 0 -30px -90px 80px;
  }

  .form-search {
    display: flex;
    margin-left: 10px;
  }

  .form-search input {
    max-width: 100%;
    margin-top: -2px;
  }

  #list-product {
    display: block;
  }

  #p-atendimento {
    padding-top: 10px;
  }

  p {
    margin-bottom: 15px;
    padding-top: 10px;
  }

  i {
    display: none;
  }
}

@media (max-width: 768px) {
  .form-search input {
    margin: 20px 8px 0 40px !important;
    max-width: 80vh;
  }

  #list-product {
    display: block;
  }

  button {
    padding: 5px 10px !important;
    margin: -75px 0 0 0 !important;
  }

  .logo img {
    max-width: 60%;
    margin: -65px 0 0 -30px;
    padding-right: 10px;
  }

  #logo-img.sticky {
    margin-top: -30px !important;
    transition: 0.7s;
  }

  button.sticky {
    margin-top: -30px !important;
    transition: 0.7s;
  }

  #navbarsExample09 p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100% !important;
    flex-direction: row !important;
    margin: 10px;
    font-size: 20px !important;
    border-top: 1px solid #0a67a8;
  }

  #navbarsExample09 {
    position: absolute;
    max-width: 100% !important;
    height: 500px !important;
    margin: -520px 0 -800px -10px !important;
  }

  i {
    display: none;
  }
}

@media (max-width: 568px) {
  button {
    padding: 5px 10px !important;
    margin: -70px 20px 0 0 !important;
  }

  .logo img {
    max-width: 50%;
    margin: -60px 0 0 -30px;
  }

  nav.sticky {
    height: 20%;
    transition: 0.7s;
  }

  #logo-img.sticky {
    margin-top: -60px !important;
    transition: 0.7s;
  }

  button.sticky {
    margin-top: -70px !important;
    transition: 0.7s;
  }

  .form-search input {
    margin:  40px 20px 0 0 !important;
    max-width: 90vw;
  }
}

@media (max-width: 390px) {
  .logo img {
    max-width: 50%;
    margin: -60px 0 0 -24px;
  }
  
  button {
    padding: 5px 10px !important;
    margin: 0 25px 65px 0 !important;
  }

  button.sticky {
    margin-top: -1px !important;
    transition: 0.7s;
  }
}

</style>
